.sticky-table {
  border-collapse: separate;
  border-spacing: 0;
}

.sticky-left-cell {
  position: sticky;
  left: 0;
  background-color: white !important;
  z-index: 2;
}

.inline-cell {
  white-space: nowrap;
}

.group-wrap {
  display: block;
  flex-wrap: wrap;
}

.badge-nothome {
  color: black;
  position: absolute;
  top: 30%;
  left: 20%;
  font-size: 0.8em;
  font-weight: bold;
  width: 1em;
}

.container-nothome {
  position: relative;
  height: 0.75em;
  width: 1.5em;
  z-index: 1;
  display: inline-block;
}

.nothome-envelope {
  opacity: 0.4;
}

.available {
  background-color: rgba(0, 255, 0, 0.1) !important;
}

.cell-highlight {
  box-shadow: 0px 0px 10px 2px green inset !important;
}

.brand-wrap {
  white-space: normal;
}

/* https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/ */
@media screen and (max-width: 450px) {
  .fluid-button {
    font-size: clamp(0.5rem, 2.8vw, 2rem);
    font-weight: bolder;
  }
}

@media screen and (max-width: 450px) {
  .fluid-bolding {
    font-weight: bolder;
  }
}

@media screen and (max-width: 450px) {
  .fluid-text {
    font-size: clamp(0.5rem, 3.2vw, 2rem);
  }
}

.landed-unit {
  height: 2rem;
}

.forgot-password {
  cursor: pointer;
}

.forgot-password:hover {
  text-decoration: underline;
}

.blinking {
  animation: blinker 1s linear infinite;
  font-weight: 500;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.text-highlight {
  text-shadow: 2px 3px 3px rgba(0, 0, 0, 0.3);
  font-weight: bold;
}

.help-button {
  cursor: pointer;
  margin: 0 0.25rem 0 0.25rem;
}
