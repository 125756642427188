.admin-unit-header:hover {
  background-color: #e7f1ff;
}

.aggregate-dark-text {
  color: black;
}

.back-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  cursor: pointer;
}

.no-confirm-btn {
  margin-left: 1.5rem;
}

.dropdown-btn {
  display: inline-block;
  margin: 0.25rem;
}

.resend-text {
  cursor: pointer;
  margin-bottom: 1.25rem;
  color: #0d6efd;
}

.resend-text:hover {
  text-decoration: underline;
}

.warning-help-button {
  position: absolute;
  right: 0.25rem;
  top: 0.55rem;
}
